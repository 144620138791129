import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";

import { ErrorSentry } from "./components/util/ErrorSentry";
import Loader from "./components/common/Loader";
import PageNotFound from "./components/util/404";
import { gaTrackPageView } from "./lib/GoogleAnalytics";
//Route-based code splitting to allow min bundle size with site use for multiple individual purposes
const RaffleWrapper = lazy(() => import("./components/raffle/RaffleWrapper"));

class _Routes extends React.Component {
  componentDidMount() {
    this.props.history.listen((location) => {
      gaTrackPageView(location);
    });
  }

  render() {
    return (
      <Switch>
        <Route
          path="/granddraw/:sellerSlug?/:teamSlug?"
          component={RaffleWrapper}
        />
        <Route exact path="/404" component={PageNotFound} />
        <Redirect path="*" to="/404" />
      </Switch>
    );
  }
}

var Routes = withRouter(_Routes);

export default class App extends React.Component {
  render() {
    return (
      <ErrorSentry>
        <Router>
          <Suspense
            fallback={
              <div className="full-page-loader">
                <Loader />
              </div>
            }
          >
            <Routes />
          </Suspense>
        </Router>
      </ErrorSentry>
    );
  }
}
