import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { HelmetProvider } from "react-helmet-async";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { gaInit } from "./lib/GoogleAnalytics";
import loadPolyfills from "./lib/loadPolyfills";
import * as serviceWorker from "./serviceWorker";
import "./styles/styles.scss";

loadPolyfills();

gaInit();

ReactDOM.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
