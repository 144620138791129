import React from "react";

const Loader = () => (
  <div className="box">
    <div className="top" />
    <div className="right" />
    <div className="bottom" />
    <div className="left" />
  </div>
);

export default Loader;
