import ReactGA from "react-ga4";

const { REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID } = process.env;

export function gaInit() {
  if (REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID) {
    ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID);
    if (window && window.location) {
      gaTrackPageView(window.location);
    }
  }
}

export function gaTrackPageView(location) {
  if (REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID && location) {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }
}
